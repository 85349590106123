import cx from 'classnames';
import React from 'react';
import Hero from "../../../../assets/images/setup/hero.png";
import Image1 from "../../../../assets/images/setup/image-1.png";
import Image2 from "../../../../assets/images/setup/image-2.png";
import Image3 from "../../../../assets/images/setup/image-3.png";
import Image4 from "../../../../assets/images/setup/image-4.png";
import useMixpanel from '../../../hooks/useMixpanel';
import useModal from '../../../hooks/useModal';
import * as styles from './Setup.module.scss';
import SetupModal from './SetupModal';

const Setup = () => {
  const { isOpen, openModal, closeModal } = useModal();
  const trackEvent = useMixpanel();

  const handleClick = (e) => {
    e.preventDefault();
    trackEvent("Clicked Learn More");
    openModal();
  }

  return (
    <div className={cx("container", styles.container)}>
      <SetupModal isOpen={isOpen} closeModal={closeModal} />
      <section className={cx("section-container", styles.heroContainer)}>
        <div className={cx("section", styles.heroContent)}>
          <div className={cx("section-text-container", styles.heroTextContainer)}>
            <div className={styles.logo} />
            <h1 className={styles.heroTitle}>
              <span>1:1 Advice</span> from Freelancer-Focused Financial Experts
            </h1>
            <p>Get 1:1 a la carte guidance via video calls from seasoned CFOs, accountants, and tax pros who specialize in working with freelancers. No long term commitments - just expert advice when you need it.</p>
          </div>
          <div className={styles.heroImgContainer}>
            <img className={styles.heroImg} src={Hero} alt="freelancer"/>
            <h1 className={cx(styles.heroTitle, styles.heroTitleMobile)}>
              <span>1:1 Advice</span> from Freelancer-Focused Financial Experts
            </h1>
          </div>
        </div>
      </section>
      <section className={cx("section-container", styles.section1)}>
        <div className={cx("section", "section--reverse")}>
          <div className={styles.sectionImgContainer}>
            <img src={Image1} alt="freelancer"/>
          </div>
          <div className={cx("section-text-container", styles.sectionTextContainer)}>
            <h3 className={styles.sectionTitle}>
              Optimize your financial wellness.
            </h3>
            <p>The Setup offers personalized support to optimize your financial strategy—whether it’s retirement planning, investment management, or tackling complex tax issues- use The Setup at will to quickly get the advice you need.</p>
            <button  onClick={handleClick} className={styles.button}>Learn More</button>
          </div>
        </div>
      </section>
      <section className={cx("section-container")}>
        <div className="section">
          <div className={styles.sectionImgContainer}>
            <img src={Image2} alt="freelancer"/>
          </div>
          <div className={cx("section-text-container", styles.sectionTextContainer)}>
            <h3 className={styles.sectionTitle}>
              Leverage experts who understand your unique freelancer needs.
            </h3>
            <p>Freelancers deserve advice that fits. Book virtual one-on-one sessions with seasoned CFOs, accountants, and tax experts who provide tailored guidance on everything <strong>from planning for retirement and unlocking tax breaks to evaluating whether you should rent or buy a home.</strong></p>
          </div>
        </div>
      </section>
      <section className={cx("section-container", styles.section3)}>
        <div className={cx("section", "section--reverse")}>
          <div className={styles.sectionImgContainer}>
            <img src={Image3} alt="freelancer"/>
          </div>
          <div className={cx("section-text-container", styles.sectionTextContainer)}>
            <h3 className={styles.sectionTitle}>
              A la carte 1-on-1 virtual sessions.
            </h3>
            <p>Book a time that works best for you, share your questions in advance, and receive tailored, strategic advice during a focused 60-minute video call.</p>
          </div>
        </div>
      </section>
      <section className={cx("section-container", styles.section4)}>
        <div className="section">
          <div className={styles.sectionImgContainer}>
            <img src={Image4} alt="freelancer"/>
          </div>
          <div className={cx("section-text-container", styles.sectionTextContainer)}>
            <h3 className={styles.sectionTitle}>
              Get great advice with no strings attached.
            </h3>
            <p>You don't have to commit to paying for a year of financial guidance and we won’t try to upsell you on more services—use The Setup when you need it.</p>
            <button  onClick={handleClick} className={styles.button}>Learn More</button>
          </div>
        </div>
      </section>
      <section className={cx("section-footer", styles.sectionFooter)}>
        <div className={cx('section-footer-content', styles.sectionFooterContent)}>
          <h2>Financial Advice for Freelancers, From Experts Who Get It.</h2>
        </div>
      </section>
      <footer className={cx("footer", styles.footer)}>
        2024 The Setup
      </footer>
    </div>
  )
}

export default Setup;